import { React, useState, forwardRef } from 'react'

import {
  Button,
  SimpleForm,
  FileField,
  FileInput,
  useDataProvider,
  useNotify
} from 'react-admin'
import {
  Box,
  Dialog,
  DialogContent,
  Slide,
  Typography
} from '@mui/material'
import { useMutation } from 'react-query'
import FileUploadIcon from '@mui/icons-material/FileUpload'

import { StyledDialogTitle } from 'styles'
import useOnError from 'common/error'

const Transition = forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const validateFile = (file) => {
  return new Promise((resolve, reject) => {
    const requiredHeaders = ['alias', 'company', 'workspace', 'application']
    const reader = new FileReader()

    reader.onload = (event) => {
      const content = event.target.result
      const lines = content.split(/\r?\n/)

      if (lines.length > 200) {
        return reject(new Error('Too many lines in the uploaded file. Maximum allowed is 200.'))
      }

      // Validate headers
      const headers = lines[0].split(',') // Adjust delimiter if needed
      const missingHeaders = requiredHeaders.filter((header) => !headers.includes(header))
      if (missingHeaders.length > 0) {
        return reject(new Error(`Missing required headers: ${missingHeaders.join(', ')}`))
      }

      resolve(file) // File is valid
    }

    reader.onerror = () => {
      reject(new Error('Failed to read the uploaded file.'))
    }

    reader.readAsText(file)
  })
}

const ImportButton = () => {
  /** Dialog state variables */
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const notify = useNotify()
  const onError = useOnError()
  const dataProvider = useDataProvider()
  const { mutate } = useMutation(
    {
      mutationFn: (fileObj) => dataProvider.import('domains', fileObj),
      onSuccess: (data) => {
        handleClose()
        notify(
          `The file has been uploaded successfully! 
          The results of the import operation will be sent to your email.`,
          { type: 'info', autoHideDuration: 5000 }
        )
      },
      onError: (error) => {
        handleClose()
        onError(error)
      }
    }
  )

  const uploadFile = async (data) => {
    const file = data.files.rawFile

    try {
      await validateFile(file)
      mutate(data.files)
    } catch (error) {
      notify(error.message, { type: 'error' })
    }
  }

  return (
    <>
      <Button label="Import" sx={{ minWidth: '5.5rem', height: '1.75rem' }} onClick={handleOpen}>
        <FileUploadIcon />
      </Button>
      <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}>
        <StyledDialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <FileUploadIcon />
            <Typography variant='h6'> Import Domains</Typography>
          </Box>
        </StyledDialogTitle>
        <DialogContent>
          <SimpleForm onSubmit={uploadFile}>
              <FileInput
                source="files"
                label="Upload a CSV file containing domain information."
                accept="text/csv"
                maxSize={100000}
                >
                  <FileField source="src" title="title" />
              </FileInput>
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ImportButton
