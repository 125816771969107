import { fetchUtils } from 'react-admin'
import simpleRestProvider from 'ra-data-simple-rest'
import { stringify } from 'query-string'
import { apiUrl } from 'config'
import acessToken from 'inMemory/accessToken'
import product from 'inMemory/product'

const httpClient = (url, options = {}) => {
  if (options.headers === undefined || options.headers == null) {
    options.headers = new Headers({ Accept: '*/*' })
  } else {
    options.headers.set('Accept', '*/*')
  }

  const token = acessToken.getToken()
  options.headers.set('Authorization', `Bearer ${token}`)

  options.headers.set('x-company-id', product.getCompany())
  options.headers.set('x-workspace-id', product.getWorkspace())
  options.headers.set('x-application-id', product.getApplication())

  return fetchUtils.fetchJson(url, options)
}

const dataProvider = simpleRestProvider(apiUrl, httpClient)

const ruleEngineDataProvider = {
  ...dataProvider,
  getList: (resource, params) => {
    const query = {
      page: params.pagination.page,
      page_size: params.pagination.perPage,
      filter: JSON.stringify(params.filter)
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`
    return httpClient(url).then(({ headers, json }) => {
      return {
        data: json.data,
        total: json.total
      }
    })
  },
  getMany: (resource, params) => {
    return Promise.all(
      params.ids.map(id => httpClient(`${apiUrl}/${resource}/${id}`))
    ).then(responses => ({
      data: responses.map(({ json }) => json)
    }))
  },
  create: (resource, params) => {
    const url = (resource === 'blocklists')
      ? `${apiUrl}/${resource}/${params.data.key}`
      : `${apiUrl}/${resource}`
    return httpClient(url, {
      method: 'POST',
      body: JSON.stringify(params.data)
    }).then(({ json }) => ({ data: json }))
  },
  delete: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'text/plain'
      })
    }).then(({ json }) => ({ data: json }))
  },
  addRule: (params) => {
    return Promise.all(
      params.rules.map(id =>
        httpClient(`${apiUrl}/rule_books/${params.bookId}/rules`, {
          method: 'PATCH',
          body: JSON.stringify({ rule_id: id })
        }))
    ).then(responses => ({
      data: responses.map(({ json }) => json)
    }))
  },
  removeRule: (params) => {
    return httpClient(`${apiUrl}/rule_books/${params.bookId}/rules`, {
      method: 'DELETE',
      body: JSON.stringify(params.ruleId)
    }).then(({ json }) => ({ data: json }))
  },
  import: (resource, params) => {
    const formData = new FormData()
    formData.append('uploaded_file', params.rawFile)

    return httpClient(`${apiUrl}/${resource}/import`, {
      method: 'POST',
      body: formData
    }).then(({ json }) => ({ data: json }))
      .catch((error) => {
        Promise.reject(error)
      })
  }
}

export default ruleEngineDataProvider
