import { styled } from '@mui/system'
import { DialogTitle } from '@mui/material'
import { CreateButton, ExportButton, FilterButton } from 'react-admin'

export const ButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 0.25rem;
  margin-right: 0.25rem;
`

export const StyledCreateButton = styled(CreateButton)`
  min-width: 5.5rem;
  margin-right: 0.5rem;
  height: 1.75rem;
`

export const StyledExportButton = styled(ExportButton)`
  min-width: 5.5rem;
  height: 1.75rem;
`
export const StyledFilterButton = styled(FilterButton)`
  min-width: 7.5rem;
`

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(1, 2),
  borderBottom: `1px solid ${theme.palette.divider}`
}))
