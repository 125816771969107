import { React, useState, useEffect } from 'react'
import {
  Box,
  Tabs,
  Tab,
  Stepper,
  Step,
  StepLabel,
  Stack,
  Typography,
  Divider
} from '@mui/material'
import {
  ArrayField,
  SingleFieldList,
  useDataProvider,
  useCreatePath,
  Loading
} from 'react-admin'

import AccountTreeIcon from '@mui/icons-material/AccountTree'
import StorefrontIcon from '@mui/icons-material/Storefront'
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning'
import BusinessIcon from '@mui/icons-material/Business'
import WorkIcon from '@mui/icons-material/Work'
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications'
import BadgeIcon from '@mui/icons-material/Badge'
import Face4Icon from '@mui/icons-material/Face4'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'

import { SimpleChipField } from 'common/fields'
import { a11yProps, TabPanel } from 'components/TabPanel'
import { StyledLink } from 'components/styled/Link'

const DomainTabs = ({ record }) => {
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Hierarchy" icon={<AccountTreeIcon />} iconPosition="start" {...a11yProps(0)} />
          <Tab label="Product Data" icon={<StorefrontIcon />} iconPosition="start" {...a11yProps(1)} />
          <Tab label="related domains" icon={<EscalatorWarningIcon />} iconPosition="start" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <HierarchyTab value={value} index={0} record={record} />
      <ProductTab value={value} index={1} record={record} />
      <RelatedTab value={value} index={2} record={record} />
    </Box>
  )
}

const HierarchyTab = (props) => {
  const { record, ...otherProps } = props
  return (
    <TabPanel {...otherProps}>
      <Stack direction="column" spacing={2}>
        <Stepper orientation="vertical">
          <Step key="company">
            <StepLabel StepIconComponent={stepIcon}>{record.company}</StepLabel>
          </Step>
          <Step key="workspace">
            <StepLabel StepIconComponent={stepIcon}>{record.workspace}</StepLabel>
          </Step>
          <Step key="application">
            <StepLabel StepIconComponent={stepIcon}>{record.application}</StepLabel>
          </Step>
        </Stepper>
        <Typography component="h6" gutterBottom>
          Tags
        </Typography>
        <ArrayField source="tags" label="Tags" record={record}>
          <SingleFieldList>
            <SimpleChipField />
          </SingleFieldList>
        </ArrayField>
        <Typography component="h6" gutterBottom>
          Service Ids
        </Typography>
        <ArrayField source="service_ids" record={record}>
          <SingleFieldList>
            <SimpleChipField />
          </SingleFieldList>
        </ArrayField>
      </Stack>
    </TabPanel>
  )
}

const ProductTab = (props) => {
  const { record, ...otherProps } = props
  return (
    <TabPanel {...otherProps}>
      <Stack direction="column">
        <Box display="flex">
          <BadgeIcon fontSize="large" color="light-grey" />
          <Box ml={2}>
            <Typography variant="h8" color="textSecondary">
              Merchant Name
            </Typography>
            <Typography variant="body2">
              {record.product_data?.merchant_name || 'No Merchant Name Available'}
            </Typography>
          </Box>
        </Box>
        <Box display="flex">
          <Face4Icon fontSize="large" color="light-grey" />
          <Box ml={2}>
            <Typography variant="h8" color="textSecondary">
              Friendly Name
            </Typography>
            <Typography variant="body2">
              {record.product_data?.friendly_name || 'No Friendly Name Available'}
            </Typography>
          </Box>
        </Box>
        <Box display="flex">
          <BusinessIcon fontSize="large" color="light-grey" />
          <Box ml={2}>
            <Typography variant="h8" color="textSecondary">
              Segment
            </Typography>
            <Typography variant="body2">
              {record.product_data?.segment || 'No Segment Available'}
            </Typography>
          </Box>
        </Box>
        <Box display="flex">
          <MonetizationOnIcon fontSize="large" color="light-grey" />
          <Box ml={2}>
            <Typography variant="h8" color="textSecondary">
              Net Revenue
            </Typography>
            <Typography variant="body2">
              {record.product_data?.net_revenue || 'No Net Revenue Available'}
            </Typography>
          </Box>
        </Box>
        <Box display="flex">
          <CreditCardOffIcon fontSize="large" color="light-grey" />
          <Box ml={2}>
            <Typography variant="h8" color="textSecondary">
              Chargeback Responsible
            </Typography>
            <Typography variant="body2">
              {record.product_data?.is_cbk_responsible === true
                ? 'True'
                : record.product_data?.is_cbk_responsible === false
                  ? 'False'
                  : 'No Data Available'}
            </Typography>
          </Box>
        </Box>
        <Box display="flex">
          <PointOfSaleIcon fontSize="large" color="light-grey" />
          <Box ml={2}>
            <Typography variant="h8" color="textSecondary">
              EC Numbers
            </Typography>
            {record.product_data?.ec_numbers
              ? (
              <ArrayField source="product_data.ec_numbers" record={record}>
                <SingleFieldList>
                  <SimpleChipField />
                </SingleFieldList>
              </ArrayField>
                )
              : (
              <Typography variant="body2"> No data Available </Typography>
                )}
          </Box>
        </Box>
      </Stack>
    </TabPanel>
  )
}

const RelatedTab = (props) => {
  const dataProvider = useDataProvider()
  const createPath = useCreatePath()
  const [displayDomains, setDisplayDomains] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { record, value } = props
  const isActive = value === 2
  const isParent = record.id === record.domain_id

  useEffect(() => {
    if (isActive) {
      setIsLoading(true)

      dataProvider
        .getList('domains', {
          filter: { domain_id: record.domain_id },
          pagination: { page: 1, perPage: 10000 }
        })
        .then(({ data }) => {
          if (isParent) {
            const childDomains = data.filter((domain) => domain.id !== domain.domain_id)
            setDisplayDomains(childDomains)
          } else {
            const parentDomains = data.filter((domain) => domain.id === domain.domain_id)
            setDisplayDomains(parentDomains)
          }
          setIsLoading(false)
        })
        .catch((error) => {
          setIsLoading(false)
          console.error('Cannot load domains', error)
        })
    }
  }, [isActive, isParent, record, dataProvider])

  if (!isActive) {
    return null
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <TabPanel {...props}>
      <Stack spacing={1}>
      <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1, marginBottom: 1 }}>
        {displayDomains.length > 0
          ? (
              isParent ? <strong>Children Domains:</strong> : <strong>Parent Domain:</strong>
            )
          : (
          <strong>No Related Domains</strong>
            )}
      </Typography>
        {displayDomains.map((domain) => (
          <StyledLink
            key={domain.id}
            to={createPath({
              resource: 'domains',
              id: domain.id,
              type: 'edit'
            })}
          >
            <Typography sx={{ color: '#1976d2' }} variant="span">
              {domain.alias}
            </Typography>
            <Divider />
          </StyledLink>
        ))}
      </Stack>
    </TabPanel>
  )
}

const stepIcon = (props) => {
  const { className } = props

  const icons = {
    1: <BusinessIcon />,
    2: <WorkIcon />,
    3: <SettingsApplicationsIcon />
  }

  return <div className={className}>{icons[String(props.icon)]}</div>
}

export default DomainTabs
